// Generated by Framer (0b5b7f5)

import { addFonts, addPropertyControls, ControlType, cx, CycleVariantState, useComponentViewport, useLocaleInfo, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion, MotionConfigContext } from "framer-motion";
import * as React from "react";

const cycleOrder = ["FP2U0plZ5", "qTg8rI1Hb", "rXPoHACOc"];

const serializationHash = "framer-LpLWs"

const variantClassNames = {FP2U0plZ5: "framer-v-17087hp", qTg8rI1Hb: "framer-v-1vt0g2b", rXPoHACOc: "framer-v-xxbadc"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const transition1 = {damping: 60, delay: 0, mass: 1, stiffness: 500, type: "spring"}

const Transition = ({value, children}) => {
const config = React.useContext(MotionConfigContext)
const transition = value ?? config.transition
const contextValue = React.useMemo(() => ({ ...config, transition }), [JSON.stringify(transition)])
return <MotionConfigContext.Provider value={contextValue}>{children}</MotionConfigContext.Provider>
}

const Variants = motion(React.Fragment)

const humanReadableVariantMap = {Desktop: "FP2U0plZ5", Phone: "rXPoHACOc", Tablet: "qTg8rI1Hb"}

const getProps = ({backgroundColor, height, id, width, ...props}) => { return {...props, variant: humanReadableVariantMap[props.variant] ?? props.variant ?? "FP2U0plZ5", Zjjq8_EKE: backgroundColor ?? props.Zjjq8_EKE ?? "var(--token-77312929-6fbe-4ed4-98e9-c0391342e4fa, rgb(252, 248, 244))"} }

const createLayoutDependency = (props, variants) => {if (props.layoutDependency) return variants.join('-') + props.layoutDependency
return variants.join('-')}

export interface Props extends React.HTMLAttributes<HTMLDivElement> { style?: Record<string, unknown>;className?: string;layoutId?: string | number;variant?: keyof typeof humanReadableVariantMap;backgroundColor?: string; }

const Component = React.forwardRef<HTMLDivElement, Props>(function(props, ref) {

const { activeLocale, setLocale } = useLocaleInfo()

const {style, className, layoutId, variant, Zjjq8_EKE, ...restProps} = getProps(props)

const {baseVariant, classNames, clearLoadingGesture, gestureHandlers, gestureVariant, isLoading, setGestureState, setVariant, variants} = useVariantState({cycleOrder, defaultVariant: "FP2U0plZ5", variant, variantClassNames})

const layoutDependency = createLayoutDependency(props, variants)

const ref1 = React.useRef<HTMLElement>(null)

const defaultLayoutId = React.useId()

const sharedStyleClassNames = []

const componentViewport = useComponentViewport()

return (<LayoutGroup id={layoutId ?? defaultLayoutId}>
<Variants animate={variants} initial={false}><Transition value={transition1}><motion.div {...restProps} {...gestureHandlers} className={cx(serializationHash, ...sharedStyleClassNames, "framer-17087hp", className, classNames)} data-framer-name={"Desktop"} layoutDependency={layoutDependency} layoutId={"FP2U0plZ5"} ref={ref ?? ref1} style={{backgroundColor: Zjjq8_EKE, ...style}} {...addPropertyOverrides({qTg8rI1Hb: {"data-framer-name": "Tablet"}, rXPoHACOc: {"data-framer-name": "Phone"}}, baseVariant, gestureVariant)}/></Transition></Variants>
</LayoutGroup>)

});

const css = ["@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-LpLWs.framer-l0dic9, .framer-LpLWs .framer-l0dic9 { display: block; }", ".framer-LpLWs.framer-17087hp { align-content: center; align-items: center; display: flex; flex-direction: column; flex-wrap: nowrap; gap: 60px; height: 72px; justify-content: center; overflow: hidden; padding: 0px; position: relative; width: 1200px; }", "@supports (background: -webkit-named-image(i)) and (not (font-palette:dark)) { .framer-LpLWs.framer-17087hp { gap: 0px; } .framer-LpLWs.framer-17087hp > * { margin: 0px; margin-bottom: calc(60px / 2); margin-top: calc(60px / 2); } .framer-LpLWs.framer-17087hp > :first-child { margin-top: 0px; } .framer-LpLWs.framer-17087hp > :last-child { margin-bottom: 0px; } }", ".framer-LpLWs.framer-v-1vt0g2b.framer-17087hp { width: 810px; }", ".framer-LpLWs.framer-v-xxbadc.framer-17087hp { width: 390px; }"]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 72
 * @framerIntrinsicWidth 1200
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["fixed","fixed"]},"qTg8rI1Hb":{"layout":["fixed","fixed"]},"rXPoHACOc":{"layout":["fixed","fixed"]}}}
 * @framerVariables {"Zjjq8_EKE":"backgroundColor"}
 * @framerImmutableVariables true
 * @framerDisplayContentsDiv false
 * @framerComponentViewportWidth true
 */
const FramerhR1OX5KtU: React.ComponentType<Props> = withCSS(Component, css, "framer-LpLWs") as typeof Component;
export default FramerhR1OX5KtU;

FramerhR1OX5KtU.displayName = "Navigation/Navigation Bar spacer";

FramerhR1OX5KtU.defaultProps = {height: 72, width: 1200};

addPropertyControls(FramerhR1OX5KtU, {variant: {options: ["FP2U0plZ5", "qTg8rI1Hb", "rXPoHACOc"], optionTitles: ["Desktop", "Tablet", "Phone"], title: "Variant", type: ControlType.Enum}, Zjjq8_EKE: {defaultValue: "var(--token-77312929-6fbe-4ed4-98e9-c0391342e4fa, rgb(252, 248, 244)) /* {\"name\":\"Light orange\"} */", title: "Background color", type: ControlType.Color}} as any)

addFonts(FramerhR1OX5KtU, [{explicitInter: true, fonts: []}], {supportsExplicitInterCodegen: true})